body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Montserrat', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a {
  text-decoration: none;
  cursor: pointer;
}

.fadeIn {
  animation: fadeIn ease-out 250ms;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

#Layer1 {
  animation-duration: 3s;
  animation-name: fadeIn;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  transition: 750ms;
  opacity: 0;
  animation-fill-mode: forwards;
}
#Layer2 {
  animation-duration: 3s;
  animation-name: fadeIn;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  transition: 750ms;
  animation-delay: 1s;
  opacity: 0;
  animation-fill-mode: forwards;
}
#Layer3 {
  animation-duration: 3s;
  animation-name: fadeIn;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  transition: 750ms;
  animation-delay: 1500ms;
  opacity: 0;
  animation-fill-mode: forwards;
}
#Layer4 {
  animation-duration: 3s;
  animation-name: fadeIn;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  transition: 750ms;
  animation-delay: 2s;
  opacity: 0;
  animation-fill-mode: forwards;
}
#Layer5 {
  animation-duration: 3s;
  animation-name: fadeIn;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  transition: 750ms;
  animation-delay: 2500ms;
  opacity: 0;
  animation-fill-mode: forwards;
}
#Layer6 {
  animation-duration: 3s;
  animation-name: fadeIn;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  transition: 750ms;
  animation-delay: 3s;
  opacity: 0;
  animation-fill-mode: forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

a {
  color: inherit;
}

.horizontal {
  grid-column: span 2;
}

.vertical {
  grid-row: span 2;
}

.big {
  grid-column: span 2;
  grid-row: span 2;
}

/*BURGR ICON*/

.burger-menu,
.burger-menu.open {
  display: inline-block;
  cursor: pointer;
  position: relative;
  z-index: 9999;
  padding: 1em;
  margin-top: 0.7em;
}

.burger-menu .bar1,
.bar2,
.bar3 {
  width: 25px;
  height: 3px;
  background-color: #ccc;
  border-radius: 5px;
  margin: 0.25em 0;
  transition: 0.4s;
}

.burger-menu.open .bar1 {
  -webkit-transform: rotate(-45deg) translate(-4px, 4px);
  transform: rotate(-45deg) translate(-4px, 4px);
}

.burger-menu.open .bar2 {
  opacity: 0;
}

.burger-menu.open .bar3 {
  -webkit-transform: rotate(45deg) translate(-6px, -6px);
  transform: rotate(45deg) translate(-6px, -6px);
}

/* This handles the embed popup videos */
.wistia_embed .wistia_click_to_play,
.wistia_embed .wistia_click_to_play div,
.wistia_embed .wistia_click_to_play div img {
  height: 100%!important;
  width: 100%!important;
}

.wistia_embed .wistia_click_to_play div,
.wistia_embed .wistia_click_to_play div img {
  position: unset!important;
}

.wistia_popover_overlay {
  backdrop-filter: blur(3px);
}